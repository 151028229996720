import { useEffect } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard } from 'mdb-react-ui-kit';
import '../styles/main.css';
import logo from '../img/logo.png'
import { Navbar } from '../component/navbar'
import { IoTicketOutline } from "react-icons/io5";
import {api} from "../controllers/api_controller"

function CreateRaffle() {

    useEffect(() => {
        document.title = "Criar Campanha | Dashboard Larifa"
        window.scrollTo(0, 0);

        const hash = window.location.hash;
        if (hash) {
            const element = document.getElementById(hash.replace('#', ''));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }

    }, [])

    return (
        <>
            <div className='top-bar'>
                <img src={logo} alt='Logo Thiago'></img>
                <p>Dashboard - Criar Campanha</p>
            </div>
            <Navbar />
            <MDBContainer fluid className='container-offset'>
                <div className='content-holder'>
                    <MDBRow className='main-dashboard-screen'>
                        <MDBCol md={12}>
                            <MDBCard className='raffles-list'>
                                <h3>Em Desenvolvimento</h3>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </div>
            </MDBContainer >
        </>
    )
}

export default CreateRaffle;