import { useEffect, useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard } from 'mdb-react-ui-kit';
import '../styles/main.css';
import logo from '../img/logo.png';
import { Navbar } from '../component/navbar';
import { IoTicketOutline } from "react-icons/io5";
import { rafflesSummary } from '../controllers/rafflesController';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
    const navigate = useNavigate();
    const [summary, setSummary] = useState({
        totalRafflesSold: 0,
        totalRevenue: 0,
        campaigns: []
    });

    useEffect(() => {
        const loadSummary = async () => {
            try {
                const summaryData = await rafflesSummary();
                if(summaryData.authError){
                    navigate('/login');
                }
                setSummary(summaryData); // Atualize o estado com os dados recebidos
            } catch (error) {
                console.error("Erro ao buscar o resumo das rifas: ", error);
            }
        };

        document.title = "Inicio | Dashboard Larifa";
        window.scrollTo(0, 0);
        loadSummary(); // Carrega os dados assim que o componente for montado
    }, []);

    return (
        <>
            <div className='top-bar'>
                <img src={logo} alt='Logo Thiago'></img>
                <p>Dashboard - Todas Campanhas</p>
            </div>
            <Navbar />
            <MDBContainer fluid className='container-offset'>
                <div className='content-holder'>
                    <MDBRow className='main-dashboard-screen'>
                        <MDBCol size={6}>
                            <MDBCard className='raffles-sold-number'>
                                <MDBRow className='raffles-sold-content'>
                                    <MDBCol md={2} className='raffles-sold-number-icon'>
                                        <IoTicketOutline />
                                    </MDBCol>
                                    <MDBCol md={10} className='raffles-sold-number-text'>
                                        <MDBRow>
                                            Número de rifas vendidas
                                        </MDBRow>
                                        <MDBRow>
                                            # {summary.totalRafflesSold}
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol size={6}>
                            <MDBCard className='raffles-sold-number'>
                                <MDBRow className='raffles-sold-content'>
                                    <MDBCol md={2} className='raffles-sold-number-icon'>
                                        <IoTicketOutline />
                                    </MDBCol>
                                    <MDBCol md={10} className='raffles-sold-number-text'>
                                        <MDBRow>
                                            Valor total de rifas vendidas
                                        </MDBRow>
                                        <MDBRow>
                                            {parseFloat(summary.totalRevenue).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
                                        </MDBRow>
                                    </MDBCol>
                                </MDBRow>
                            </MDBCard>
                        </MDBCol>
                        <MDBCol md={12}>
                            <MDBCard className='raffles-list'>
                                <h3>Rifas</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Campanhas</th>
                                            <th>Números Gerados</th>
                                            <th>Números Vendidos</th>
                                            <th>Valor Total Vendido</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {summary.campaigns.map(campaign => (
                                            <tr key={campaign.campaign_id}>
                                                <td>{campaign.campaign_name}</td>
                                                <td>{campaign.total_raffles}</td>
                                                <td>{campaign.number_sold}</td>
                                                <td>{parseFloat(campaign.value_sold).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </div>
            </MDBContainer >
        </>
    );
}

export default Dashboard;
