import { api } from "./api_controller";


const fetchUsers = async () => {
    const token = localStorage.getItem('token');

    try {
        const response = await fetch(`${api}/backoffice/users/list`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        // Verifica se a resposta é um erro de autenticação e, em caso afirmativo, trata esse erro.
        if (!response.ok) {
            console.log(response)

            // Lógica de tratamento, como redirecionar o usuário para o login ou exibir uma mensagem
            return { authError: true }
        }

        const usersData = await response.json();
        return usersData;
    } catch (error) {
        console.error('Failed to fetch users:', error);
        return { authError: true }
    }
};

export { fetchUsers };