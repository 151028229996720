import { api } from "./api_controller";

const login = async (username, password) => {
    const loginDetails = new FormData();
    loginDetails.append('username', username);
    loginDetails.append('password', password);

    try {
        const response = await fetch(`${api}/backoffice/login`, {
            method: 'POST',
            // Removido 'Content-Type': 'application/json', pois FormData automaticamente define o cabeçalho correto
            body: loginDetails
        });

        if (!response.ok) {
            throw new Error('Ocorreu um erro durante a solicitação.');
        }

        const responseData = await response.json();
        
        return responseData;
    } catch (error) {
        console.error(error.message);
        return null;
    }
};


export { login }