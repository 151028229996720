import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import Index from './screen'
import Users from './screen/users'
import Payments from './screen/payments'
import Raffle from './screen/raffle'
import CreateRaffle from './screen/create_raffle'
import Login from './screen/login'

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" index element={<Index />}/>
        <Route path="/usuarios" index element={<Users />}/>
        <Route path="/pagamentos" index element={<Payments />}/>
        <Route path="/campanha/:id" index element={<Raffle />}/>
        <Route path="/criar-campanha" index element={<CreateRaffle />}/>
        <Route path="/login" index element={<Login />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;