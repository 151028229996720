import { api } from "./api_controller";

const rafflesSummary = async () => {
    const token = localStorage.getItem('token');

    try {
        const response = await fetch(`${api}/backoffice/campaign/all/summary`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        // Verifica se a resposta é um erro de autenticação e, em caso afirmativo, trata esse erro.
        if (!response.ok) {
            // Lógica de tratamento, como redirecionar o usuário para o login ou exibir uma mensagem
            return { authError: true }
        }

        const usersData = await response.json();
        return usersData;
    } catch (error) {
        console.error('Failed to fetch users:', error);
        return { authError: true }
    }
};

const fetchAllCampaigns = async () => {
    const token = localStorage.getItem('token');

    try {
        const response = await fetch(`${api}/backoffice/campaign/all/list
        `, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        // Verifica se a resposta é um erro de autenticação e, em caso afirmativo, trata esse erro.
        if (!response.ok) {
            // Lógica de tratamento, como redirecionar o usuário para o login ou exibir uma mensagem
            return { authError: true }
        }

        const campaignData = await response.json();
        return campaignData;
    } catch (error) {
        console.error('Failed to fetch users:', error);
        return { authError: true }
    }
};

// Função ajustada para buscar as rifas de uma campanha específica
const fetchAllRafflesOfCampaigns = async (idCampaign) => {
    const token = localStorage.getItem('token');

    try {
        const response = await fetch(`${api}/backoffice/campaign/${idCampaign}/raffles/list`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (!response.ok) {
            return { authError: true }
        }

        const campaignData = await response.json();
        return campaignData;
    } catch (error) {
        console.error('Falha ao buscar rifas da campanha:', error);
        return { authError: true }
    }
};

export { rafflesSummary, fetchAllCampaigns, fetchAllRafflesOfCampaigns };