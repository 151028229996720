import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBCardBody, MDBSpinner, MDBBtn, MDBRow, MDBCol, MDBCard } from 'mdb-react-ui-kit';
import logo from '../img/logo.png';
import { IoMdLogIn } from "react-icons/io";
import { TiWarningOutline } from "react-icons/ti";
import ParticlesBackground from '../component/particlesBackground';
import { login } from '../controllers/authController';
import { useNavigate } from 'react-router-dom';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [loginWarning, setLoginWarning] = useState({ status: false, message: '' });
    const [loginLoading, setLoginLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Login | Dashboard Larifa";
        window.scrollTo(0, 0);
        localStorage.removeItem('token');
    }, []);

    const handleLogin = async () => {
        setLoginLoading(true);
        const loginResponse = await login(username, password);
        if (loginResponse.success && loginResponse.token) {
            // Sucesso no login
            setLoginLoading(false);
            setLoginWarning({ status: false, message: '' });
            localStorage.setItem('token', loginResponse.token);
            navigate('/');
        } else {
            // Falha no login
            setLoginLoading(false);
            setLoginWarning({ status: true, message: 'Usuário ou senha incorretos. Por favor, tente novamente.' });
        }
    };

    return (
        <>
            <ParticlesBackground />
            <MDBContainer fluid>
                <MDBRow className="justify-content-center align-items-center" style={{ height: '90vh' }}>
                    <MDBCol md={4}>
                        <MDBCard style={{ borderRadius: '30px', backgroundColor: '#580000' }}>
                            <img style={{ padding: '15px', borderRadius: '35px' }} src={logo} alt='Logo Larifa' className="img-fluid" />
                            <MDBCardBody className='login-card'>
                                <h1 style={{ fontSize: '1.2rem' }}>
                                    <span style={{ padding: '0.5rem 1rem', borderRadius: '30px', color: '#FFF', backgroundColor: '#98000044', display: 'inline-flex', marginBottom: '1rem', justifyContent: 'center', alignItems: 'center' }}>
                                        <IoMdLogIn style={{ marginRight: '0.5rem' }} size={'1.5rem'} />Fazer login
                                    </span>
                                </h1>
                                <p style={{ fontSize: '0.9rem', fontWeight: 400 }}>Para acessar o dashboard de gerência das rifas, insira suas informações de login nos campos abaixo.
                                </p>
                                <input type="text" name='username' placeholder="digite aqui seu usuário" style={{ borderRadius: '10px' }} className="form-control mb-2 mt-1" onChange={e => setUsername(e.target.value)} />
                                <input type="password" name='password' placeholder="digite aqui sua senha" style={{ borderRadius: '10px' }} className="form-control mb-3 mt-1" onChange={e => setPassword(e.target.value)} />
                                {loginWarning.status && <p style={{ fontSize: '0.9rem', color: 'red' }}><TiWarningOutline style={{ marginRight: '0.5rem' }} />{loginWarning.message}</p>}
                                <MDBBtn className='login-btn' style={{ backgroundColor: '#980000', borderRadius: '25px', textTransform: 'initial', fontSize: '0.9rem', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }} onClick={handleLogin}>
                                    {!loginLoading ?
                                        'Acessar Dashboard'
                                        :
                                        <MDBSpinner color='light' size="sm">
                                            <span className='visually-hidden'>Carregando...</span>
                                        </MDBSpinner>
                                    }
                                </MDBBtn>
                            </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </>
    );
}

export default Login;
