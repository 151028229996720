import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBInput } from 'mdb-react-ui-kit';
import logo from '../img/logo.png';
import { Navbar } from '../component/navbar';
import { fetchAllRafflesOfCampaigns } from '../controllers/rafflesController';
import { useNavigate } from 'react-router-dom';

function Raffle() {
    const navigate = useNavigate();

    const [filterValues, setFilterValues] = useState({
        raffleNumber: '',
        buyerName: '',
        phone: '',
        email: ''
    });
    const [allRaffles, setAllRaffles] = useState([]);
    const [displayedRaffles, setDisplayedRaffles] = useState([]);
    const [loading, setLoading] = useState(false);
    const { id } = useParams();

    // Carrega as rifas ao montar e quando o id da campanha mudar
    useEffect(() => {
        document.title = 'Rifa | Dashboard Larifa';
        setLoading(true);
        const loadRaffles = async () => {
            const rafflesData = await fetchAllRafflesOfCampaigns(id);
            if(rafflesData.authError){
                navigate('/login');
            }
            setAllRaffles(rafflesData);
            setDisplayedRaffles(rafflesData.slice(0, 15));
            setLoading(false);
        };
        loadRaffles();
    }, [id]);

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFilterValues(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const filteredRaffles = useCallback(() => {
        const filtered = allRaffles.filter(raffle => {
            return (
                raffle['Número da Rifa'].toString().toLowerCase().includes(filterValues.raffleNumber.toLowerCase()) &&
                raffle['Nome do Comprador'].toLowerCase().includes(filterValues.buyerName.toLowerCase()) &&
                raffle['Telefone'].toLowerCase().includes(filterValues.phone.toLowerCase()) &&
                raffle['E-mail'].toLowerCase().includes(filterValues.email.toLowerCase())
            );
        });
        return filtered.slice(0, displayedRaffles.length);
    }, [allRaffles, filterValues, displayedRaffles.length]);

    const loadMoreRaffles = () => {
        if (displayedRaffles.length >= allRaffles.length) {
            // Todos os itens já estão sendo exibidos
            return;
        }
        const nextRaffles = allRaffles.slice(displayedRaffles.length, displayedRaffles.length + 15);
        setDisplayedRaffles(displayedRaffles.concat(nextRaffles));
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || loading) {
                return;
            }
            loadMoreRaffles();
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loading, displayedRaffles, allRaffles]);

    return (
        <>
            <div className='top-bar'>
                <img src={logo} alt='Logo Larifa' />
                <p>Dashboard - Rifa</p>
            </div>
            <Navbar />
            <MDBContainer fluid className='container-offset'>
                <div className='content-holder'>
                    <MDBRow className='main-dashboard-screen'>
                        <MDBCard className='form-card'>
                            <label style={{ borderLeft: '5px solid #980000', paddingLeft: '7px' }}>
                                Utilize os campos abaixo para filtrar as rifas
                            </label>
                            <form className='raffle-filter'>
                                <MDBInput label='Número da Rifa' id='raffleNumber' type='text' name='raffleNumber' onChange={handleInputChange} />
                                <MDBInput label='Nome do Comprador' id='buyerName' type='text' name='buyerName' onChange={handleInputChange} />
                                <MDBInput label='Número de Telefone' id='phone' type='text' name='phone' onChange={handleInputChange} />
                                <MDBInput label='E-mail' id='email' type='email' name='email' onChange={handleInputChange} />
                            </form>
                        </MDBCard>
                        <MDBCol md={12}>
                            <MDBCard className='raffle-list'>
                                <h3>Rifas da Campanha</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Número da Rifa</th>
                                            <th>Nome do Comprador</th>
                                            <th>Status</th>
                                            <th>Telefone</th>
                                            <th>E-mail</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredRaffles().map((raffle, index) => (
                                            <tr key={index}>
                                                <td>{raffle["Número da Rifa"]}</td>
                                                <td>{raffle["Nome do Comprador"]}</td>
                                                <td>{raffle["Status"]}</td>
                                                <td>{raffle["Telefone"]}</td>
                                                <td>{raffle["E-mail"]}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                {loading && <p>Carregando mais...</p>}
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </div>
            </MDBContainer>
        </>
    );
}

export default Raffle;
