import React, { useState, useEffect, useRef } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBInput } from 'mdb-react-ui-kit';
import logo from '../img/logo.png';
import { Navbar } from '../component/navbar';
import { fetchPayments } from '../controllers/paymentController';
import { useNavigate } from 'react-router-dom';

function Payments() {
    const [filterValues, setFilterValues] = useState({ name: '', status: '' });
    const [allPayments, setAllPayments] = useState([]); // Todos os pagamentos carregados
    const [displayedPayments, setDisplayedPayments] = useState([]); // Pagamentos atualmente exibidos
    const [currentPage, setCurrentPage] = useState(1); // Página atual para controle de exibição
    const loadingRef = useRef(null); // Ref para o elemento "carregando mais"
    const itemsPerPage = 10; // Número de itens por página
    const navigate = useNavigate();


    useEffect(() => {
        document.title = "Pagamentos | Dashboard Larifa";
        window.scrollTo(0, 0);
        const loadPayments = async () => {
            const fetchedPayments = await fetchPayments();
    
            console.log(fetchedPayments);
            if (fetchedPayments.authError) {
                navigate('/login');
            } else if (Array.isArray(fetchedPayments)) { // Verifica se fetchedPayments é realmente um array
                setAllPayments(fetchedPayments);
                setDisplayedPayments(fetchedPayments.slice(0, itemsPerPage));
            } else {
                console.error('A resposta recebida não é um array:', fetchedPayments);
                // Trate o caso em que a resposta não é um array
            }
        };
        loadPayments();
    }, []);
    

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                const first = entries[0];
                if (first.isIntersecting) {
                    loadMoreItems(); // Carrega mais itens quando o observador intersectar
                }
            },
            { threshold: 0.5 }
        );

        const currentElement = loadingRef.current;
        if (currentElement) {
            observer.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                observer.unobserve(currentElement);
            }
        };
    }, [allPayments, displayedPayments]);

    const loadMoreItems = () => {
        const nextPage = currentPage + 1;
        const morePayments = allPayments.slice(0, itemsPerPage * nextPage);
        setDisplayedPayments(morePayments);
        setCurrentPage(nextPage);
    };

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFilterValues((prevState) => ({ ...prevState, [id]: value }));
    };

    // Filtragem baseada nos valores dos inputs
    const filteredPayments = allPayments.filter(payment =>
        (filterValues.name === '' || payment.pay_user_fullname.toLowerCase().includes(filterValues.name.toLowerCase())) &&
        (filterValues.status === '' || payment.pay_status.toLowerCase() === filterValues.status.toLowerCase())
    );

    // Função para traduzir o status do pagamento
    const translateStatus = (status) => {
        switch (status) {
            case 'approved':
                return 'Aprovado';
            case 'pending':
                return 'Pendente';
            case 'cancelled':
                return 'Cancelado';
            default:
                return status; // Retorna o status sem tratamento caso não corresponda a nenhum dos 3
        }
    };

    return (
        <>
            <div className='top-bar'>
                <img src={logo} alt='Logo Thiago'></img>
                <p>Dashboard - Pagamentos</p>
            </div>
            <Navbar />
            <MDBContainer fluid className='container-offset'>
                <div className='content-holder'>
                    <MDBRow className='main-dashboard-screen'>
                        <MDBCard className='form-card'>
                            <label style={{ borderLeft: '5px solid #980000', paddingLeft: '7px' }}>Caso necessário, utilize os campos abaixo para realizar uma pesquisa dos pagamentos em andamento</label>
                            <form className='users-filter'>
                                <MDBInput label='Nome' id='name' type='text' onChange={handleInputChange} />
                                <select id="status" name="status" className="form-select" onChange={handleInputChange}>
                                    <option value="">Todos os Status</option>
                                    <option value="approved">Aprovado</option>
                                    <option value="pending">Pendente</option>
                                    <option value="cancelled">Cancelado</option>
                                </select>
                            </form>
                        </MDBCard>
                        <MDBCol md={12}>
                            <MDBCard className='users-list'>
                                <h3>Pagamentos</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Nome</th>
                                            <th>Quantidade</th>
                                            <th>Valor Unidade</th>
                                            <th>Valor Total</th>
                                            <th>Data de Pagamento</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredPayments.map((payment, index) => (
                                            <tr key={index}>
                                                <td>{payment.pay_user_fullname}</td>
                                                <td>{payment.pay_qtd}</td>
                                                <td>{parseFloat(payment.pay_unit_value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                <td>{parseFloat(payment.pay_total_amount).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}</td>
                                                <td>{new Date(payment.pay_date).toLocaleDateString('pt-BR')}</td>
                                                <td>{translateStatus(payment.pay_status)}</td>
                                            </tr>
                                        ))}
                                        {displayedPayments.length < allPayments.length && (
                                            <tr ref={loadingRef}>Carregando mais...</tr>
                                        )}
                                    </tbody>
                                </table>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </div>
            </MDBContainer >
        </>
    );
}

export default Payments;
