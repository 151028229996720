import React, { useEffect, useRef } from 'react';

const ParticlesBackground = () => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        canvas.width = window.innerWidth;
        canvas.height = window.innerHeight;

        let particlesArray = [];
        const mouse = {
            x: null,
            y: null,
            radius: 100 // Raio de interação com o mouse
        }

        // Atualiza a posição do mouse sempre que ele se move
        window.addEventListener('mousemove', function (event) {
            mouse.x = event.x;
            mouse.y = event.y;
        });

        class Particle {
            constructor() {
                this.x = Math.random() * canvas.width;
                this.y = Math.random() * canvas.height;
                this.size = 2;
                this.speedX = Math.random() * 3 - 1.5;
                this.speedY = Math.random() * 3 - 1.5;
            }

            update() {
                this.x += this.speedX;
                this.y += this.speedY;

                // Verifica se a partícula saiu do lado direito da tela e reposiciona no lado esquerdo
                if (this.x > canvas.width) {
                    this.x = 0;
                } else if (this.x < 0) {
                    this.x = canvas.width;
                }

                // Verifica se a partícula saiu do topo da tela e reposiciona no fundo
                if (this.y > canvas.height) {
                    this.y = 0;
                } else if (this.y < 0) {
                    this.y = canvas.height;
                }
            }

            draw() {
                ctx.fillStyle = '#98000050';
                ctx.beginPath();
                ctx.arc(this.x, this.y, this.size, 0, Math.PI * 2);
                ctx.fill();
            }
        }

        function init() {
            particlesArray = [];
            for (let i = 0; i < 100; i++) {
                particlesArray.push(new Particle());
            }
        }

        function connect() {
            let opacity = 1;
            for (let a = 0; a < particlesArray.length; a++) {
                for (let b = a; b < particlesArray.length; b++) {
                    let distance = ((particlesArray[a].x - particlesArray[b].x) ** 2) + ((particlesArray[a].y - particlesArray[b].y) ** 2);
                    if (distance < (canvas.width / 7) * (canvas.height / 7)) {
                        opacity = 1 - (distance / 20000);
                        ctx.strokeStyle = 'rgba(152,0,0,' + opacity + ')';
                        ctx.lineWidth = 1;
                        ctx.beginPath();
                        ctx.moveTo(particlesArray[a].x, particlesArray[a].y);
                        ctx.lineTo(particlesArray[b].x, particlesArray[b].y);
                        ctx.stroke();
                    }
                }

                // Agora, vamos corrigir o desenho das linhas para o mouse
                // Essa parte do código deveria estar fora do loop for anterior para evitar referenciar `this`
            }

            // Desenha linhas do mouse para partículas próximas
            particlesArray.forEach(particle => {
                let distance = ((mouse.x - particle.x) ** 2) + ((mouse.y - particle.y) ** 2);
                if (distance < mouse.radius ** 2) { // Verifica se a partícula está dentro do raio de ação do mouse
                    ctx.strokeStyle = 'rgba(152,0,0,0.1)';
                    ctx.lineWidth = 1;
                    ctx.beginPath();
                    ctx.moveTo(mouse.x, mouse.y);
                    ctx.lineTo(particle.x, particle.y);
                    ctx.stroke();
                }
            });
        }

        function animate() {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            for (let i = 0; i < particlesArray.length; i++) {
                particlesArray[i].update();
                particlesArray[i].draw();
            }
            connect();
            requestAnimationFrame(animate);
        }

        init();
        animate();

        // Ajusta o tamanho do canvas quando a janela for redimensionada
        window.addEventListener('resize', () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        });

        // Atualiza a posição do mouse para nula quando o usuário sai da janela
        window.addEventListener('mouseout', function () {
            mouse.x = undefined;
            mouse.y = undefined;
        })
    }, []);

    return <canvas ref={canvasRef} className="canvas-background"></canvas>;
};

export default ParticlesBackground;
