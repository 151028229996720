import { useEffect, useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBInput } from 'mdb-react-ui-kit';
import '../styles/main.css';
import logo from '../img/logo.png'
import { Navbar } from '../component/navbar'
import { fetchUsers } from '../controllers/userController';
import { useNavigate } from 'react-router-dom';


function Users() {
    const navigate = useNavigate();


    const [filterValues, setFilterValues] = useState({
        username: '',
        fullname: '',
        phone: '',
        email: '',
        cpf: ''
    });

    const [users, setUsers] = useState([]);

    useEffect(() => {
        // Chame a função fetchUsers quando o componente for montado
        const loadUsers = async () => {
            try {
                const usersData = await fetchUsers();

                console.log(usersData);

                console.log(users);
                if(usersData.authError){
                    navigate('/login');
                }
                setUsers(usersData); // Atualize o estado com os usuários recebidos
            } catch (error) {
                console.error("Erro ao buscar os usuários: ", error);
                // Você pode querer atualizar o estado aqui para mostrar um erro ao usuário
            }
        };

        document.title = "Usuários | Dashboard Larifa";
        window.scrollTo(0, 0);
        loadUsers(); // Execute a função de carregamento de usuários

        const hash = window.location.hash;
        if (hash) {
            const element = document.getElementById(hash.replace('#', ''));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }

    }, []);

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        setFilterValues(prevState => ({
            ...prevState,
            [id]: value
        }));
    };

    const filteredUsers = users.filter(user => {
        const usernameMatch = filterValues.username === '' || user.username.toLowerCase().includes(filterValues.username.toLowerCase());
        const fullnameMatch = filterValues.fullname === '' || user.full_name.toLowerCase().includes(filterValues.fullname.toLowerCase());
        const phoneMatch = filterValues.phone === '' || user.phone_number.includes(filterValues.phone);
        const emailMatch = filterValues.email === '' || user.email.toLowerCase().includes(filterValues.email.toLowerCase());
        const cpfMatch = filterValues.cpf === '' || user.cpf.includes(filterValues.cpf);
    
        return usernameMatch && fullnameMatch && phoneMatch && emailMatch && cpfMatch;
    });
    
    

    return (
        <>
            <div className='top-bar'>
                <img src={logo} alt='Logo Thiago'></img>
                <p>Dashboard - Usuários</p>
            </div>
            <Navbar />
            <MDBContainer fluid className='container-offset'>
                <div className='content-holder'>
                    <MDBRow className='main-dashboard-screen'>
                        <MDBCard className='form-card'>
                            <label style={{ borderLeft: '5px solid #980000', paddingLeft: '7px' }}>Caso necessário, utilize os campos abaixo para realizar uma pesquisa dos usuários cadastrados</label>
                            <form className='users-filter'>
                                <MDBInput label='Nome de Usuário' id='username' type='text' onChange={handleInputChange} />
                                <MDBInput label='Nome Completo' id='fullname' type='text' onChange={handleInputChange} />
                                <MDBInput label='Número de Telefone' id='phone' type='text' onChange={handleInputChange} />
                                <MDBInput label='E-mail' id='email' type='email' onChange={handleInputChange} />
                                <MDBInput label='CPF' id='cpf' type='text' onChange={handleInputChange} />
                            </form>
                        </MDBCard>
                        <MDBCol md={12}>
                            <MDBCard className='users-list'>
                                <h3>Usuários</h3>
                                <table>
                                    <thead>
                                        <tr>
                                            <th>ID do usuário</th>
                                            <th>Nome de Usuário</th>
                                            <th>Nome Completo</th>
                                            <th>E-mail</th>
                                            <th>Telefone</th>
                                            <th>CPF</th>
                                            <th>Data de Criação</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredUsers.map(user => (
                                            <tr key={user.id}>
                                                <td>{user.id}</td>
                                                <td>{user.username}</td>
                                                <td>{user.full_name}</td>
                                                <td>{user.email}</td>
                                                <td>{user.phone_number}</td>
                                                <td>{user.cpf}</td>
                                                <td>{user.created_at}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </div>
            </MDBContainer >
        </>
    )
}

export default Users;
